.collections {
  width: 100%;
  padding: 72px 0 0 0;
  align-self: flex-start;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;

  .input-wrapper.text {
    margin: 0 24px 24px 24px;
  }

  .table-wrapper {
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 13px;
  
      thead {
        position: sticky;
        top: 0;
        z-index: 1;
      }
  
      th {
        background: #212731;
        color: white;
        font-weight: 600;
        text-align: left;
        padding: 18px 16px;
        border-right: 1px solid #E2E7EF;

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .ordering {
            width: 24px;
            height: 24px;

            svg {
              fill: white;
            }
          }
        }

      }
  
      td {
        padding: 8px 16px;

        &:has(div.label) {
          div.label {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        div {
          width: 25vw;

          &.label {
            width: 10vw;
          }

          &.code {
            width: 5vw;
          }
        }
  
        div.ferroloc, div.type, div.techno {
          display: flex;
          flex-wrap: wrap;
          gap: 2px;
  
          span {
            padding: 2px 4px;
            background-color: #374459;
            color: white;
            border-radius: 4px;
          }
        }
      }
  
      tr:nth-child(odd) {
        background: #F5F9FF;
      }
  
      tr.skeleton {
        height: 54px;

        td {
          div {
            opacity: 0.8;
            height: 24px;
            animation: skeleton 1s linear infinite alternate;
            border-radius: 3px;
          }
        }
      }

      tr:not(.skeleton):hover {
        cursor: pointer;
        background: #EEF3FB;
      }
    }

    h3 {
      text-align: center;
      margin-top: 20vh;
    }

    &.panel-openned {
      table td div {
        width: 12vw;

        &.label {
          width: 7vw;
        }

        &.code {
          width: 4vw;
        }
      }
    }
  }



  @keyframes skeleton {
    0% {
      background-color: hsl(203, 25%, 83%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
}